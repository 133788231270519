<template>
  <div class="tnc-wrapper">
    <b-card-title
      title-tag="h3"
      class="font-weight-bolder mb-1"
    >
      Syarat dan Ketentuan
      <b-link
        href="https://toba.ai/terms-of-service"
        target="_blank"
      >
        <feather-icon
          icon="HelpCircleIcon"
          size="24"
          class="text-primary cursor-pointer align-midlle ml-50"
        />
      </b-link>
    </b-card-title>
    <div class="tnc-content mb-1">
      <p>Selamat datang di <a href="https://toba.ai" target="_blank">https://toba.ai</a>.</p>
      <p>Berikut adalah Syarat & Ketentuan untuk layanan Toba.AI. Syarat & Ketentuan ini mengatur Anda dalam menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>; dengan mendaftar dan/atau menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>, maka Anda dianggap telah menerima, memahami dan menyetujui seluruh isi dalam Syarat & Ketentuan. Jika Anda tidak menyetujui salah satu, pesebagian, atau seluruh isi dari Syarat & Ketentuan ini, maka Anda tidak diperkenankan menggunakan layanan di <a href="https://toba.ai" target="_blank">https://toba.ai</a>.</p>
      <p>Dengan menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> dan menyetujui Syarat & Ketentuan ini, maka Anda telah menyetujui penggunaan cookie sesuai dengan ketentuan Kebijakan Privasi dari Toba.AI.</p>

      <h5>Lisensi Penggunaan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a></h5>
      <p>Kecuali dinyatakan lain, Toba.AI dan/atau pemberi lisensi memiliki hak kekayaan intelektual dan materiil yang ada di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> yang termasuk namun tidak terbatas pada nama dan logo, kode, desain, teknologi, model bisnis, yang mana telah dilindungi hak cipta, merek dagang dan hak kekayaan intelektual lainnya, baik yang terdaftar maupun tidak. Patuh pada lisensi di bawah, semua hak kekayaan intelektual ini dilindungi oleh undang-undang.</p>
      <p>Toba.AI memberikan layanan langganan non-eksklusif dan tidak dapat dialihkan dalam menggunakan Layanan selama Ketentuan, semata-mata agar penggunaan internal pengguna tetap sesuai dengan parameter penggunaan yang telah dijelaskan dalam formulir pemesanan berlangganan serta patuh pada Syarat & Ketentuan.</p>
      <p>Dengan menggunakan Toba.AI untuk mengumpulkan data dan mengoptimalkan akun media sosial, Anda setuju untuk terikat dengan Ketentuan Layanan kami.</p>

      <h5>Menyiapkan Akun Anda</h5>
      <ul>
        <li>Anda harus memberikan informasi yang akurat, lengkap, dan terbaru. Seperti nama lengkap resmi, email aktif, dan informasi lainnya yang diminta selama proses pendaftaran agar mendapatkan akun dan dapat menggunakan Layanan Toba.AI, dan untuk memperbarui informasi ini jika berubah.</li>
        <li>Untuk membuat akun dan menggunakan Layanan Toba.AI, Anda harus manusia dalam arti bukan dibuat oleh “bot” atau metode otomatis lainnya.</li>
        <li>Anda bertanggung jawab untuk menjaga keamanan akun dan kata sandi milik Anda, dan juga bertanggung jawab atas segala aktivitas lainnya yang menggunakan akun atau kata sandi. Toba.AI tidak bertanggung jawab atas kehilangan atau kerusakan yang diakibatkan oleh kegagalan Anda dalam mematuhi kewajiban ini atau penggunaan akun Anda yang tidak sah.</li>
        <li>Anda tidak boleh menggunakan akun orang lain tanpa izin.</li>
        <li>Anda harus segera memberi tahu Toba.AI mengenai pelanggaran keamanan atau penggunaan akun Anda yang tidak sah.</li>
      </ul>

      <h5>Biaya dan Pembayaran</h5>
      <p>Ketentuan pembayaran dan penetapan harga untuk Layanan Toba.AI adalah sebagaimana ditentukan dalam formulir pemesanan yang digunakan untuk memesan langganan tersebut.</p>
      <p>Dengan berlangganan layanan di Toba.AI, Anda menyetujui untuk membayar biaya berlangganan bulanan atau tahunan. Anda akan dikenakan tagihan pembayaran pada hari setelah melakukan pemesanan dan telah mencakup Penggunaan layanan tersebut untuk periode yang dipesan. Biaya berlangganan yang telah dikirim, tidak dapat dikembalikan.</p>
      <p>Toba.AI menawarkan langganan bulanan dan tahunan yang dapat diperpanjang dan dibatalkan oleh Anda sendiri. Pemberitahuan mengenai pemberhentian atau perpanjangan langganan akan Anda terima sebelum periode baru dimulai. Anda akan dikenakan tagihan untuk Layanan tersebut hingga Anda sendiri yang membatalkannya atau Toba.AI yang akan mengakhirinya. Pembatalan yang Anda lakukan, tidak berlaku hingga selesainya periode penagihan saat itu.</p>
      <p>Harga berlangganan layanan di Toba.AI dapat berubah sewaktu-waktu, maka Anda berhak mendapatkan pemberitahuan tersebut maksimal 30 hari sebelumnya. Pemberitahuan tersebut akan diposting di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> atau mungkin muncul di halaman Layanan Toba.AI. Jika Anda tidak menyetujui dengan adanya perubahan harga, maka Anda dapat membatalkan layanan tersebut untuk periode 30 hari kedepan. Tetapi, jika Anda terus menggunakan Layanan setelah tanggal efektif perubahan harga, maka Anda dengan ini menyetujui perubahan harga tersebut.</p>
      <p>Toba.AI tidak akan bertanggung jawab kepada Anda atau pihak ketiga mana pun atas segala modifikasi pada Layanan atau harga yang tertera saat ini.</p>

      <h5>Pembatalan dan Pengakhiran</h5>
      <p>Anda bertanggung jawab penuh untuk menutup akun milik Anda dengan benar.</p>
      <p>Anda dapat menutup akun kapan saja dengan mengirim email kepada kami di <b><a href="mailto:bisnis@widyaanalytic.com">bisnis@widyaanalytic.com</a></b>.</p>
      <p>Pembayaran dilakukan pada periode penagihan yang akan datang. Toba.AI tidak memberikan pengembalian uang pro-rata untuk akun yang melakukan pembatalan selama masa berlangganan.</p>

      <h5>Penggunaan yang Dapat Diterima</h5>
      <p>Anda tidak diperkenankan menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> dengan cara apapun yang menyebabkan, atau dapat menyebabkan, kerusakan pada situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> atau penurunan ketersediaan atau aksesibilitas situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>; dan dengan cara apapun atau sehubungan dengan tujuan atau aktivitas apapun yang melanggar hukum, ilegal, curang, atau berbahaya.</p>
      <p>Anda tidak diperkenankan menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> untuk menyalin, menyimpan, meng-hosting, menyebarkan, mengirim, menggunakan, menerbitkan, atau mendistribusikan materiil apapun yang terdiri dari (atau terkait dengan) spyware, virus komputer, Trojan horse, worm, keystroke logger, rootkit, atau lainnya yang berbahaya bagi perangkat lunak komputer.</p>
      <p>Anda tidak diperkenankan melakukan aktivitas pengumpulan data yang sistematis atau otomatis (termasuk namun tidak terbatas pada scraping, data mining, ekstraksi data, dan pengambilan data) pada atau sehubungan dengan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> tanpa persetujuan tertulis dari Toba.AI.</p>
      <p>Anda tidak diperkenankan menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> untuk menyebar atau mengirim komunikasi komersial yang tidak diminta.</p>

      <h5>Akses Terbatas</h5>
      <p>Akses ke area tertentu di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> telah dibatasi. Toba.AI berhak membatasi akses ke sebagian atau seluruh area di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> atas kebijakan Toba.AI.</p>
      <p>Jika Toba.AI memberikan Anda sebuah user-ID dan kata sandi, sehingga memungkinkan bagi Anda untuk mengakses area terlarang dari situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> atau konten atau layanan lainnya, maka Anda harus memastikan bahwa user-ID dan kata sandi tersebut dijaga kerahasiaannya.</p>
      <p>Toba.AI dapat menonaktifkan user-ID dan kata sandi Anda atas kebijakan Toba.AI sendiri tanpa pemberitahuan atau penjelasan sebelumnya.</p>

      <h5>Batasan Tanggung Jawab</h5>
      <p>Toba.AI tidak akan bertanggung jawab kepada Anda (baik berdasarkan hukum kontrak, hukum gugatan, atau lainnya) sehubungan dengan konten, atau penggunaan, atau sehubungan dengan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>:</p>
      <ul>
        <li>untuk setiap kerugian langsung;</li>
        <li>untuk setiap kerugian tidak langsung, khusus atau konsekuensial; atau</li>
        <li>untuk setiap kerugian bisnis, kehilangan pendapatan, pendapatan, keuntungan atau tabungan antisipasi, kehilangan kontrak atau hubungan bisnis, kehilangan reputasi atau niat baik, atau kehilangan atau kerusakan informasi atau data.</li>
      </ul>
      <p>Batasan tanggung jawab ini berlaku bahkan jika Toba.AI telah secara tegas diberitahu mengenai potensi kerugiannya.</p>

      <h5>Pengecualian</h5>
      <p>Tidak ada dalam <i>disclaimer</i>, situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> akan mengecualikan atau membatasi jaminan tersirat oleh hukum bahwa akan melanggar hukum untuk mengecualikan atau membatasi; dan tidak ada di <i>disclaimer</i>, situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> akan mengecualikan atau membatasi tanggung jawab Toba.AI sehubungan dengan:
      <ul>
        <li>kematian atau cedera pribadi yang disebabkan oleh kelalaian Toba.AI;</li>
        <li>penipuan atau misrepresentasi yang curang dari Toba.AI; atau</li>
        <li>hal yang ilegal atau melanggar hukum bagi Toba.AI untuk mengecualikan atau membatasi, atau mencoba atau bermaksud untuk mengecualikan atau membatasi, tanggung jawabnya.</li>
      </ul>

      <h5>Kelayakan</h5>
      <p>Dengan menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>, Anda menyetujui bahwa pengecualian dan batasan tanggung jawab yang ditetapkan dalam disclaimer di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> adalah wajar atau masuk akal.</p>
      <p>Jika menurut Anda tidak masuk akal atau tidak wajar, maka Anda tidak diperkenankan menggunakan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>.</p>

      <h5>Pihak Lain</h5>
      <p>Anda menerima sebagai entitas dengan kewajiban terbatas, Toba.AI berkepentingan untuk membatasi tanggung jawab pribadi pejabat dan karyawannya. Anda menyetujui bahwa Anda tidak akan mengajukan klaim apa pun secara pribadi terhadap pejabat atau karyawan Toba.AI sehubungan dengan kerugian yang Anda derita di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>.</p>
      <p>Anda menyetujui bahwa batasan jaminan dan tanggung jawab yang ditetapkan dalam disclaimer di situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> akan melindungi pejabat, karyawan, agen, anak perusahaan, penerus, penerima, dan subkontraktor Toba.AI serta Toba.AI.</p>

      <h5>Ketentuan yang Tidak Dapat Diberlakukan</h5>
      <p>Jika ada atau ditemukan ketentuan dari disclaimer situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>, yang tidak dapat diterapkan berdasarkan hukum yang berlaku, maka itu tidak akan mempengaruhi keberlakuan ketentuan lain dari disclaimer situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>.</p>

      <h5>Ganti Rugi</h5>
      <p>Dengan ini Anda harus memberikan ganti rugi kepada Toba.AI dan berjanji untuk melindungi Toba.AI dari kerugian, kerusakan, biaya, kewajiban dan beban (termasuk namun tidak terbatas pada biaya hukum dan setiap jumlah yang dibayarkan oleh Toba.AI kepada pihak ketiga dalam penyelesaian klaim atau perselisihan atas saran dari penasihat hukum Toba.AI) yang ditimbulkan atau diderita oleh Toba.AI sehubungan dengan segala pelanggaran yang Anda lakukan atas ketentuan dari Syarat & Ketentuan ini, atau yang timbul dari segala klaim bahwa Anda telah melanggar ketentuan dari Syarat & Ketentuan ini.</p>

      <h5>Pelanggaran Syarat & Ketentuan</h5>
      <p>Tanpa mengurangi hak-hak Toba.AI lainnya, berdasarkan Syarat & Ketentuan ini, jika Anda melanggar Syarat & Ketentuan ini dengan berbagai cara apapun, maka Toba.AI dapat mengambil tindakan yang dianggap tepat oleh Toba.AI untuk menangani pelanggaran tersebut, termasuk menangguhkan akses ke situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>, melarang mengakses situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>, memblokir komputer yang menggunakan alamat IP Anda untuk mengakses situs <a href="https://toba.ai" target="_blank">https://toba.ai</a>, menghubungi penyedia layanan internet untuk meminta mereka memblokir akses Anda ke situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> dan/atau membawanya ke proses pengadilan.</p>

      <h5>Pembaharuan</h5>
      <p>Toba.AI dapat mengubah Syarat & Ketentuan ini sewaktu-waktu tanpa pemberitahuan sebelumnya. Syarat & Ketentuan yang direvisi akan berlaku untuk penggunaan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> sejak tanggal dipublikasi. Silakan periksa halaman Syarat & Ketentuan ini secara teratur dan seksama untuk mengetahui setiap perubahan. Penggunaan yang berkelanjutan atas situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> setelah modifikasi, pembaharuan, dan/atau amandemen Persyaratan merupakan persetujuan dan penerimaan Anda atas modifikasi atau perubahan tersebut.</p>

      <h5>Penugasan</h5>
      <p>Toba.AI dapat mentransfer, mensub-kontrakan, atau dengan cara lain menangani hak dan/atau kewajiban Toba.AI berdasarkan Syarat & Ketentuan ini tanpa memberi tahu atau memperoleh persetujuan dari Anda.</p>
      <p>Anda tidak diperkenankan mentransfer, mensub-kontrakan, atau berurusan dengan hak dan/atau kewajiban Anda berdasarkan Syarat & Ketentuan ini.</p>

      <h5>Keterpisahan</h5>
      <p>Jika suatu ketentuan dari Syarat & Ketentuan ini ditentukan oleh pengadilan atau otoritas berwenang lainnya sebagai tidak sah dan/atau tidak dapat dilaksanakan, maka ketentuan lainnya akan tetap berlaku. Jika ada ketentuan yang melanggar hukum dan/atau tidak dapat dilaksanakan maka akan menjadi sah atau tetap dapat dilaksanakan jika sebagian darinya dihapus, sebagian itu akan dianggap dihapus dan ketentuan lainnya akan tetap terus berlaku.</p>

      <h5>Seluruh Perjanjian</h5>
      <p>Syarat & Ketentuan ini merupakan keseluruhan perjanjian antara Anda dengan Toba.AI dan menggantikan semua perjanjian sebelumnya sehubungan dengan penggunaan situs <a href="https://toba.ai" target="_blank">https://toba.ai</a> oleh Anda.</p>

      <h5>Detail Toba.AI</h5>
      <p>Toba.AI adalah layanan yang ditawarkan oleh PT Widya Intelektual Bangsa.</p>
      <p>Anda dapat menghubungi Toba.AI melalui email <b><a href="mailto:bisnis@widyaanalytic.com">bisnis@widyaanalytic.com</a></b></p>
      <p>Dokumen ini terakhir diperbarui pada 27 September 2021</p>
    </div>
  </div>
</template>

<script>
import {
  BLink, BCardTitle,
} from 'bootstrap-vue'


export default {
  components: {
    BLink,
    BCardTitle,
  },
}
</script>
<style lang="scss">
.tnc-wrapper {
  height: 699px;
  @media (max-width: 576px) {
    height: 399px;
  }
  .tnc-content {
    overflow: auto;
    height: calc(100% - 40px);
    line-height: 200%;
    text-align: justify;
    text-justify: inter-word;
    padding-right: 8px;
  }
}
</style>
